/* post */

.postWaiting {
    width: 50%;
    margin-top: 15px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}


/* post Bottom */
.postBottom {
    width: 20%;
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 1px 1px;
}