.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); 
  }
  
  .modal-Form{
    position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  }
  
  .display-block {
    display: block;

  }
  
  .display-none {
    display: none;

  }
  