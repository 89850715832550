/* post */

.postWaiting {
    width: 100%;
    margin-top: 15px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.postAccepted
{
  width: 50%;
  margin-top: 15px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
  color: green;
}
.postRejected
{
  width: 100%;
  margin-top: 15px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
  color: red;
}


/* post Top */
.postTop {
    display: flex;
    position: relative;
    align-items: center;
    padding: 15px;
}
.postAvatar {
    margin-right: 10px;
}
.postTopInfo h3 {
    font-size: medium;
}
.postTopInfo p {
    font-size: small;
    color: gray;
}

/* post Bottom */
.postBottom {
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 1px 1px;
}

/* post Image */
.postImage img {
    width: 100%;
}

/* post Options */
.postOptions {
    padding-top: 10px;
    border-top: 1px solid lightgray;
    display: flex;
    justify-content: space-evenly;
    font-size: medium;
    color: gray;
    cursor: pointer;
    padding: 15px;
}
.postOption {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    flex: 1;
}
.postOption p {
    margin-left: 10px;
}
.postOption:hover {
    background-color: #eff2f5;
    border-radius: 10px;
}
