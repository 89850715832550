.app {
    background-color: #f1f2f5;
    /* height: 100vh; */
}
.appBody {
    display: flex;
    justify-content: center;
    align-items:center;
}
.dashboard-container {
    display: flex;
    flex-direction: row;
}
.dashboard-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #F0F2F5;
}