.feed {
    flex: 1;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
}

.postWaiting {
    display: flex;
    justify-self: center;
}
.requests {
    width: 90%;
}
.responded{
    width: 50%;
    margin-left: 5px;
}
